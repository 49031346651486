import React, { useState } from 'react';
import CountdownTimer from './components/Countdown';
import Modal from './components/Modal';
import './App.css';
import sparkMindsLogo from './assets/cut.png';

const App: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'info' | 'notify'>('info');
  const [showLaunchButton, setShowLaunchButton] = useState(false);

  const handleMoreInfoClick = () => {
    setModalType('info');
    setModalOpen(true);
  };

  const handleNotifyMeClick = () => {
    setModalType('notify');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleShowLaunchButton = () => {
    setShowLaunchButton(true);
  };

  const handleLaunchClick = () => {
    window.open('http://sparkminds.live/', '_blank'); // Replace with your website URL
  };

  return (
    <div className="coming-soon">
      <div className="overlay">
        <div className="logo">
          <img src={sparkMindsLogo} alt="SparkMinds Logo" className="sparkminds-logo" />
          <p>Coming Soon</p>
        </div>
        
        {/* Pass the handler to CountdownTimer to show the launch button before 5 minutes */}
        <CountdownTimer onBeforeFiveMinutes={handleShowLaunchButton} />
        
        <div className="content">
          <h2>Our New Website Launching Soon...</h2>
          <p>
            To provide specialized, high-quality courses tailored to the needs of individuals preparing to enter the tech 
            industry or considering a career transition. Our curriculum, designed by industry experts, focuses on 
            practical skills and professional development.
          </p>

          {/* Conditional rendering: Show either buttons or launch button */}
          {!showLaunchButton ? (
            <div className="buttons">
              <button onClick={handleNotifyMeClick}>Notify Me</button>
              <button onClick={handleMoreInfoClick}>More Info</button>
            </div>
          ) : (
            <div className="buttons launch-button">
              <button onClick={handleLaunchClick}>Launch Website</button>
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} type={modalType} />
    </div>
  );
};

export default App;
