import React, { useEffect, useRef } from 'react';
import './Modal.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'info' | 'notify'; // Add a type prop to differentiate modals
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, type }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // Close the modal if the user clicks outside of it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : ''}`}>
      <div ref={modalRef} className={`modal-content ${isOpen ? 'slide-in' : 'slide-out'}`}>
        <button className="close-button" onClick={onClose}>X</button>
        
        {type === 'info' ? (
          <>
            <h2>About Us</h2>
            <p>
              We are excited to announce the launch of our pioneering online education platform, SparkMinds. This 
              initiative aims to equip individuals globally with cutting-edge technical and programming skills essential for 
              success in the technology field. SparkMinds will cater to a diverse audience, from recent graduates to 
              seasoned professionals, providing them with practical learning experiences and professional development.
            </p>
            <h3>Vision and Mission</h3>
            <h4>Vision</h4>
            <p>
              To be a global leader in online technical education, empowering individuals to excel in their careers through 
              industry-leading expertise and innovative learning experiences.
            </p>
            <h4>Mission</h4>
            <p>
              To provide specialized, high-quality courses tailored to the needs of individuals preparing to enter the tech 
              industry or considering a career transition. Our curriculum, designed by industry experts, focuses on 
              practical skills and professional development.
            </p>
          </>
        ) : (
          <>
            <h2>Notify Me</h2>
            <form className="notify-form">
              <label>
                Name:
                <input type="text" name="name" required />
              </label>
              <label>
                Email:
                <input type="email" name="email" required />
              </label>
              <button type="submit">Send</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
