import React, { useState } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';

interface CountdownTimerProps {
  onBeforeFiveMinutes: () => void; // Function to notify parent when < 5 minutes
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ onBeforeFiveMinutes }) => {
  const [hasNotified, setHasNotified] = useState(false); // To avoid multiple notifications
  const launchDate = new Date('2024-09-08T11:00:00+08:00').getTime();
  const fiveMinutesInMilliseconds = 300000;

  // Custom renderer to format the countdown and handle button visibility
  const renderer = ({ days, hours, minutes, seconds, total }: CountdownRenderProps) => {
    // Check if remaining time is less than or equal to 5 minutes and notify parent
    if (total <= fiveMinutesInMilliseconds && !hasNotified) {
      onBeforeFiveMinutes();
      setHasNotified(true); // Ensure we notify only once
    }

    return (
      <div className="countdown">
        <div className="countdown-item">
          <span className="count">{days}</span>
          <span className="label">DAYS</span>
        </div>
        <div className="countdown-item">
          <span className="count">{hours}</span>
          <span className="label">HRS</span>
        </div>
        <div className="countdown-item">
          <span className="count">{minutes}</span>
          <span className="label">MIN</span>
        </div>
        <div className="countdown-item">
          <span className="count">{seconds}</span>
          <span className="label">SEC</span>
        </div>
      </div>
    );
  };

  return <Countdown date={launchDate} renderer={renderer} />;
};

export default CountdownTimer;
